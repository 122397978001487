import { useState } from 'react'
import { Role } from 'apollo/generated/graphqlClient'
import { useMe } from 'hooks/useMe'
import { disconnectWeb3Auth } from 'lib/web3Auth/provider'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { signOut } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { NEXTAUTH_COGNITO_GOOGLE_LOGOUT_URL } from 'constants/common/auth'
import { Route } from 'constants/common/routes'
import { DeployedIcon, LogoutIcon, SettingsIcon, SupportIcon, TicketIcon } from 'components/icons'
import { InvitationDialogToggle } from 'components/invitation-dialog-toggle'
import { InvitationSidebarButton } from 'components/invitation-dialog-toggle/invitation-sidebar-button'
import { LoadingOverlay } from 'components/loading-overlay/loading-overlay'
import { Logo } from 'components/logo'
import { SettingsDialogToggle } from 'components/settings-dialog-toggle'
import { Sidebar, SidebarButton, SidebarButtonGroup, SidebarSection } from 'components/sidebar'
import TooltipToggle from 'components/ui/tooltip-toggle'
import { UserAvatar } from 'components/userAvatar'

const InvitationDialogToggleButton = ({ invitesCount }: { invitesCount?: number }) => (
  <InvitationSidebarButton icon={TicketIcon} notificationsCount={invitesCount} />
)

export const AuthenticatedLayout = () => {
  const router = useRouter()
  const { me, isMeLoading } = useMe()
  const [isLoggingOut, setIsLoggingOut] = useState(false)
  const { t } = useTranslation('common')

  if (isMeLoading) {
    return <LoadingOverlay />
  }

  if (!me || !me.isProfileComplete) {
    return null
  }

  return (
    <Sidebar>
      <SidebarSection>
        <Logo />
        <SidebarButtonGroup>
          <TooltipToggle
            trigger={
              <SidebarButton
                icon={DeployedIcon}
                href={Route.Base}
                isCurrentlyVisited={router.pathname === (Route.Base as string)}
              />
            }
            contentProps={{ side: 'right' }}
          >
            {t('sidebar.tooltips.rooms')}
          </TooltipToggle>
        </SidebarButtonGroup>
      </SidebarSection>
      <SidebarSection>
        <SidebarButtonGroup>
          <TooltipToggle
            trigger={
              <SidebarButton
                onClick={async () => {
                  try {
                    await disconnectWeb3Auth()
                    setIsLoggingOut(true)
                    await signOut()
                    void router.push(NEXTAUTH_COGNITO_GOOGLE_LOGOUT_URL)
                  } finally {
                    setIsLoggingOut(false)
                  }
                }}
                icon={LogoutIcon}
                isLoading={isLoggingOut}
              />
            }
            contentProps={{ side: 'right' }}
          >
            {t('sidebar.tooltips.logOut')}
          </TooltipToggle>

          <TooltipToggle
            // NOTE @dr the help button is intentionally disabled right now (including the translations text for tooltip)
            trigger={
              <SidebarButton icon={SupportIcon} className="pointer-events-none opacity-50" />
            }
            contentProps={{ side: 'right' }}
          >
            {t('sidebar.tooltips.help')}
          </TooltipToggle>

          {me.roles.includes(Role.Platform) && (
            <TooltipToggle
              trigger={<InvitationDialogToggle toggle={InvitationDialogToggleButton} />}
              contentProps={{ side: 'right' }}
            >
              {t('sidebar.tooltips.invite')}
            </TooltipToggle>
          )}

          <TooltipToggle
            trigger={<SettingsDialogToggle toggle={<SidebarButton icon={SettingsIcon} />} />}
            contentProps={{ side: 'right' }}
          >
            {t('sidebar.tooltips.settings')}
          </TooltipToggle>
        </SidebarButtonGroup>

        <TooltipToggle
          trigger={
            <Link href={Route.ProfileSettings}>
              <UserAvatar src={me.profileImageUrl} name={me.userName as string} />
              {/* @ns: at this moment, userName is surely non null (null is only before set up profile step) */}
            </Link>
          }
          contentProps={{ side: 'right' }}
        >
          {t('sidebar.tooltips.profile')}
        </TooltipToggle>
      </SidebarSection>
    </Sidebar>
  )
}
