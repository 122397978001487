import { SVGProps } from 'react'
import clsx from 'clsx'
import { CommonButton } from 'components/common-button'

interface InvitationSidebarButtonProps {
  onClick?: () => void | Promise<void>
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
  isLoading?: boolean
  isCurrentlyVisited?: boolean
  className?: string
  notificationsCount?: number
}

export const InvitationSidebarButton = ({
  icon: Icon,
  onClick,
  notificationsCount,
  isLoading = false,
  isCurrentlyVisited = false,
  className = '',
}: InvitationSidebarButtonProps) => (
  <div className="relative">
    {notificationsCount !== undefined && notificationsCount > 0 && (
      <div className="absolute right-0 top-0 flex size-[18px] items-center justify-center rounded-full bg-primary text-2xs text-white">
        {notificationsCount}
      </div>
    )}
    <CommonButton
      iconOnly
      icon={Icon}
      variant="ghost"
      size="icon-xl"
      className={clsx(
        'bg-transparent text-white-transparent60 hover:bg-white-transparent15 hover:text-white active:bg-white-transparent15 active:text-white',
        isCurrentlyVisited && 'bg-white-transparent15 text-white',
        className,
      )}
      onClick={onClick}
      isLoading={isLoading}
    />
  </div>
)
